<template>
  <div>
    <!-- <p style="font-size:16px;">获取用户ID信息</p> -->
    <p style="font-size:16px;">正在跳转...</p>
  </div>
</template>

<script>
import ap from "@/utils/alipayjsapi.min.js";
import { getChannelUserId } from "@/api/api";
import wayCodeUtils from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from "@/config";
export default {
  components: {},
  mounted () {
    const that = this;
    let payOrderNo = config.cachePayParams || this.$route.query.payOrderNo;
    let code = this.$route.query.code;

    //优先验证是否有code
    if (!code) {
      //不存在 code 使用 小程序授权获取
      let payWay = wayCodeUtils.getPayWay()
      if (payWay.name == "zfb") {
        //支付宝小程序
        ap.getAuthCode(
          {
            appId: this.$route.query.app_id,
            scopes: ["auth_base"],
          },
          function (res) {
            console.log(res);
            let code = res.authCode;
            if (!code) {
              that.$router.push({
                name: config.errorPageRouteName,
                params: { errInfo: "授权失败" },
              });
              return;
            }
            that.getChannelUserId(payOrderNo, code);
          }
        );
        return
      }

      //微信小程序
      // if (payWay.name == "wx") {
      //   ap.getAuthCode(
      //     {
      //       appId: this.$route.query.app_id,
      //       scopes: ["auth_base"],
      //     },
      //     function (res) {
      //       console.log(res);
      //       let code = res.authCode;
      //       if (!code) {
      //         that.$router.push({
      //           name: config.errorPageRouteName,
      //           params: { errInfo: "授权失败" },
      //         });
      //         return;
      //       }
      //       that.getChannelUserId(payOrderNo, code);
      //     }
      //   );
      //   return
      // }


      this.$router.push({
        name: config.errorPageRouteName,
        params: { errInfo: "授权失败" },
      });
      return
    }

    //已获取到code ,使用code获取openId
    this.getChannelUserId(payOrderNo, code);
  },
  methods: {
    searchToObject: function () {
      if (!window.location.search) {
        return {};
      }

      var pairs = window.location.search.substring(1).split("&"),
        result = {},
        pair,
        i;
      for (i in pairs) {
        if (pairs[i] === "") continue;
        pair = pairs[i].split("=");
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
      return result;
    },
    getChannelUserId: function (payOrderNo, code) {
      getChannelUserId(payOrderNo, code)
        .then((res) => {
          //设置channelUserId
          channelUserIdUtil.setChannelUserId(res.data);

          this.$router.push({
            name: wayCodeUtils.getPayWay().routeName,
            query: { payOrderNo: payOrderNo },
          });
        })
        .catch((err) => {
          // console.log(err.message)
          this.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        });
    },
  },
};
</script>
